<template>
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="v-img-fluid v-w-100"
  >
    <path
      d="M24 13.0038C24 19.0293 15.5517 28.6757 12.8324 31.6246C12.3795 32.1158 11.6205 32.1158 11.1676 31.6246C8.44826 28.6757 0 19.0293 0 13.0038C0 9.55496 1.26428 6.2474 3.51472 3.80872C5.76515 1.37004 8.8174 0 12 0C15.1826 0 18.2348 1.37004 20.4853 3.80872C22.7357 6.2474 24 9.55496 24 13.0038Z"
      fill="#131313"
    />
    <path
      d="M24 13.0038C24 19.0293 15.5517 28.6757 12.8324 31.6246C12.3795 32.1158 11.6205 32.1158 11.1676 31.6246C8.44826 28.6757 0 19.0293 0 13.0038C0 9.55496 1.26428 6.2474 3.51472 3.80872C5.76515 1.37004 8.8174 0 12 0C15.1826 0 18.2348 1.37004 20.4853 3.80872C22.7357 6.2474 24 9.55496 24 13.0038Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M24 13.0038C24 19.0293 15.5517 28.6757 12.8324 31.6246C12.3795 32.1158 11.6205 32.1158 11.1676 31.6246C8.44826 28.6757 0 19.0293 0 13.0038C0 9.55496 1.26428 6.2474 3.51472 3.80872C5.76515 1.37004 8.8174 0 12 0C15.1826 0 18.2348 1.37004 20.4853 3.80872C22.7357 6.2474 24 9.55496 24 13.0038Z"
      fill="black"
      fill-opacity="0.2"
    />
    <ellipse
      cx="11.9999"
      cy="11.7551"
      rx="9.06122"
      ry="8.81633"
      fill="white"
    />
    <path
      d="M11.2653 9.79553C11.2653 10.066 11.046 10.2853 10.7755 10.2853C10.505 10.2853 10.2857 10.066 10.2857 9.79553V7.34655C10.2857 7.07604 10.0664 6.85675 9.7959 6.85675C9.5254 6.85675 9.30611 7.07604 9.30611 7.34655V9.79553C9.30611 10.066 9.08682 10.2853 8.81631 10.2853C8.5458 10.2853 8.32652 10.066 8.32652 9.79553V7.34655C8.32652 7.07604 8.10723 6.85675 7.83672 6.85675C7.56621 6.85675 7.34692 7.07604 7.34692 7.34655V10.2853C7.34692 11.1652 7.93067 11.9043 8.73165 12.1483C8.97913 12.2236 9.18366 12.4314 9.18366 12.6901V16.1629C9.18366 16.4334 9.40295 16.6527 9.67345 16.6527H9.91835C10.1889 16.6527 10.4081 16.4334 10.4081 16.1629V12.6901C10.4081 12.4314 10.6127 12.2236 10.8602 12.1483C11.6611 11.9043 12.2449 11.1652 12.2449 10.2853V7.34655C12.2449 7.07604 12.0256 6.85675 11.7551 6.85675C11.4846 6.85675 11.2653 7.07604 11.2653 7.34655V9.79553ZM13.7143 8.81593V12.2445C13.7143 12.515 13.9336 12.7343 14.2041 12.7343H14.449C14.7195 12.7343 14.9388 12.9536 14.9388 13.2241V16.1629C14.9388 16.4334 15.158 16.6527 15.4286 16.6527H15.6735C15.944 16.6527 16.1632 16.4334 16.1632 16.1629V7.34655C16.1632 7.07604 15.9419 6.85223 15.6765 6.90428C14.5576 7.1237 13.7143 8.06017 13.7143 8.81593Z"
      fill="#131313"
    />
    <path
      d="M11.2653 9.79553C11.2653 10.066 11.046 10.2853 10.7755 10.2853C10.505 10.2853 10.2857 10.066 10.2857 9.79553V7.34655C10.2857 7.07604 10.0664 6.85675 9.7959 6.85675C9.5254 6.85675 9.30611 7.07604 9.30611 7.34655V9.79553C9.30611 10.066 9.08682 10.2853 8.81631 10.2853C8.5458 10.2853 8.32652 10.066 8.32652 9.79553V7.34655C8.32652 7.07604 8.10723 6.85675 7.83672 6.85675C7.56621 6.85675 7.34692 7.07604 7.34692 7.34655V10.2853C7.34692 11.1652 7.93067 11.9043 8.73165 12.1483C8.97913 12.2236 9.18366 12.4314 9.18366 12.6901V16.1629C9.18366 16.4334 9.40295 16.6527 9.67345 16.6527H9.91835C10.1889 16.6527 10.4081 16.4334 10.4081 16.1629V12.6901C10.4081 12.4314 10.6127 12.2236 10.8602 12.1483C11.6611 11.9043 12.2449 11.1652 12.2449 10.2853V7.34655C12.2449 7.07604 12.0256 6.85675 11.7551 6.85675C11.4846 6.85675 11.2653 7.07604 11.2653 7.34655V9.79553ZM13.7143 8.81593V12.2445C13.7143 12.515 13.9336 12.7343 14.2041 12.7343H14.449C14.7195 12.7343 14.9388 12.9536 14.9388 13.2241V16.1629C14.9388 16.4334 15.158 16.6527 15.4286 16.6527H15.6735C15.944 16.6527 16.1632 16.4334 16.1632 16.1629V7.34655C16.1632 7.07604 15.9419 6.85223 15.6765 6.90428C14.5576 7.1237 13.7143 8.06017 13.7143 8.81593Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M11.2653 9.79553C11.2653 10.066 11.046 10.2853 10.7755 10.2853C10.505 10.2853 10.2857 10.066 10.2857 9.79553V7.34655C10.2857 7.07604 10.0664 6.85675 9.7959 6.85675C9.5254 6.85675 9.30611 7.07604 9.30611 7.34655V9.79553C9.30611 10.066 9.08682 10.2853 8.81631 10.2853C8.5458 10.2853 8.32652 10.066 8.32652 9.79553V7.34655C8.32652 7.07604 8.10723 6.85675 7.83672 6.85675C7.56621 6.85675 7.34692 7.07604 7.34692 7.34655V10.2853C7.34692 11.1652 7.93067 11.9043 8.73165 12.1483C8.97913 12.2236 9.18366 12.4314 9.18366 12.6901V16.1629C9.18366 16.4334 9.40295 16.6527 9.67345 16.6527H9.91835C10.1889 16.6527 10.4081 16.4334 10.4081 16.1629V12.6901C10.4081 12.4314 10.6127 12.2236 10.8602 12.1483C11.6611 11.9043 12.2449 11.1652 12.2449 10.2853V7.34655C12.2449 7.07604 12.0256 6.85675 11.7551 6.85675C11.4846 6.85675 11.2653 7.07604 11.2653 7.34655V9.79553ZM13.7143 8.81593V12.2445C13.7143 12.515 13.9336 12.7343 14.2041 12.7343H14.449C14.7195 12.7343 14.9388 12.9536 14.9388 13.2241V16.1629C14.9388 16.4334 15.158 16.6527 15.4286 16.6527H15.6735C15.944 16.6527 16.1632 16.4334 16.1632 16.1629V7.34655C16.1632 7.07604 15.9419 6.85223 15.6765 6.90428C14.5576 7.1237 13.7143 8.06017 13.7143 8.81593Z"
      fill="black"
      fill-opacity="0.2"
    />
  </svg>
</template>
