<template>
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="v-img-fluid v-w-100"
  >
    <path
      d="M6.59997 10.0999L7.15534 11.2103C7.18406 11.2677 7.18978 11.334 7.17133 11.3954L6.30005 14.3"
      stroke="white"
      stroke-width="1.26"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.45552 5.25002L3.85379 5.04667C3.77245 5.03634 3.69454 5.07677 3.65771 5.14844L2.3694 7.65474C2.30923 7.7718 2.38191 7.91677 2.51362 7.94241L3.71878 8.17701"
      stroke="white"
      stroke-width="1.31766"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.19885 11.3669L5.06889 9.21001C5.10642 9.18909 5.13701 9.1605 5.15959 9.12395C5.37889 8.76889 6.62514 6.7499 7.37664 5.50479C7.47208 5.34668 7.69922 5.342 7.79964 5.49701C8.41852 6.45235 8.80828 7.2111 8.96892 7.54064C9.01984 7.64509 9.13382 7.70407 9.24809 7.68296L10.8013 7.39591"
      stroke="white"
      stroke-width="1.26"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.67883 2.61185C7.67883 2.85372 7.77365 3.08569 7.94244 3.25672C8.11122 3.42775 8.34014 3.52383 8.57883 3.52383C8.81753 3.52383 9.04645 3.42775 9.21523 3.25672C9.38401 3.08569 9.47883 2.85372 9.47883 2.61185C9.47883 2.36997 9.38401 2.138 9.21523 1.96697C9.04645 1.79594 8.81753 1.69986 8.57883 1.69986C8.34014 1.69986 8.11122 1.79594 7.94244 1.96697C7.77365 2.138 7.67883 2.36997 7.67883 2.61185Z"
      fill="white"
      stroke="white"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
