<i18n>
ru:
  titleMapCourier: Курьер на карте
  helpForCourier: Возможность отслеживать курьера на карте станет доступна после назначения курьера к вашему заказу.
ua:
  titleMapCourier: Кур'єр на карті
  helpForCourier: Можливість відстеження кур'єра на карті стане доступною після призначення кур'єра до вашого замовлення.
us:
  titleMapCourier: Courier on the map
  helpForCourier: The ability to track the courier on the map will become available after assigning a courier to your order.
</i18n>

<template>
  <ClientOnly>
    <maps-yandex-maps-courier
      v-if="appConfig.VueSettingsPreRun.MapsProvider === 'yandex'"
      :courier-latitude="courierLatitude"
      :courier-longitude="courierLongitude"
      :address-latitude="addressLatitude"
      :address-longitude="addressLongitude"
      :delivery-terminal="deliveryTerminal"
    />
    <span
      v-else
      class="v-error-color"
      v-html="translate('mapsAddress.error')"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
import type { GUID } from '@arora/common'

defineProps<{
  courierLatitude?: number
  courierLongitude?: number
  addressLatitude: number
  addressLongitude: number
  deliveryTerminal: GUID
}>()
const { translate } = useI18nSanitized()
const appConfig = useAppConfig()
</script>
