<template>
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="v-img-fluid v-w-100"
  >
    <path
      d="M24 13.0038C24 19.0293 15.5517 28.6757 12.8324 31.6246C12.3795 32.1158 11.6205 32.1158 11.1676 31.6246C8.44826 28.6757 0 19.0293 0 13.0038C0 9.55496 1.26428 6.2474 3.51472 3.80872C5.76515 1.37004 8.8174 0 12 0C15.1826 0 18.2348 1.37004 20.4853 3.80872C22.7357 6.2474 24 9.55496 24 13.0038Z"
      fill="#131313"
    />
    <path
      d="M24 13.0038C24 19.0293 15.5517 28.6757 12.8324 31.6246C12.3795 32.1158 11.6205 32.1158 11.1676 31.6246C8.44826 28.6757 0 19.0293 0 13.0038C0 9.55496 1.26428 6.2474 3.51472 3.80872C5.76515 1.37004 8.8174 0 12 0C15.1826 0 18.2348 1.37004 20.4853 3.80872C22.7357 6.2474 24 9.55496 24 13.0038Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M24 13.0038C24 19.0293 15.5517 28.6757 12.8324 31.6246C12.3795 32.1158 11.6205 32.1158 11.1676 31.6246C8.44826 28.6757 0 19.0293 0 13.0038C0 9.55496 1.26428 6.2474 3.51472 3.80872C5.76515 1.37004 8.8174 0 12 0C15.1826 0 18.2348 1.37004 20.4853 3.80872C22.7357 6.2474 24 9.55496 24 13.0038Z"
      fill="black"
      fill-opacity="0.2"
    />
    <ellipse
      cx="11.9999"
      cy="11.7551"
      rx="9.06122"
      ry="8.81633"
      fill="white"
    />
    <path
      d="M12.245 7.34654C12.245 7.34654 9.21513 9.96205 7.5219 11.3785C7.468 11.4255 7.42457 11.4832 7.39445 11.548C7.36432 11.6128 7.34817 11.6832 7.34705 11.7547C7.34705 11.8846 7.39865 12.0092 7.4905 12.101C7.58236 12.1929 7.70694 12.2445 7.83684 12.2445H8.81643V15.6731C8.81643 15.803 8.86804 15.9276 8.95989 16.0194C9.05175 16.1113 9.17633 16.1629 9.30623 16.1629H10.7756C10.9055 16.1629 11.0301 16.1113 11.122 16.0194C11.2138 15.9276 11.2654 15.803 11.2654 15.6731V13.7139H13.2246V15.6731C13.2246 15.803 13.2762 15.9276 13.3681 16.0194C13.4599 16.1113 13.5845 16.1629 13.7144 16.1629H15.1838C15.3137 16.1629 15.4383 16.1113 15.5301 16.0194C15.622 15.9276 15.6736 15.803 15.6736 15.6731V12.2445H16.6532C16.7831 12.2445 16.9077 12.1929 16.9995 12.101C17.0914 12.0092 17.143 11.8846 17.143 11.7547C17.1423 11.6819 17.125 11.6102 17.0925 11.545C17.06 11.4798 17.0131 11.4229 16.9554 11.3785C15.2739 9.96205 12.245 7.34654 12.245 7.34654Z"
      fill="#131313"
    />
    <path
      d="M12.245 7.34654C12.245 7.34654 9.21513 9.96205 7.5219 11.3785C7.468 11.4255 7.42457 11.4832 7.39445 11.548C7.36432 11.6128 7.34817 11.6832 7.34705 11.7547C7.34705 11.8846 7.39865 12.0092 7.4905 12.101C7.58236 12.1929 7.70694 12.2445 7.83684 12.2445H8.81643V15.6731C8.81643 15.803 8.86804 15.9276 8.95989 16.0194C9.05175 16.1113 9.17633 16.1629 9.30623 16.1629H10.7756C10.9055 16.1629 11.0301 16.1113 11.122 16.0194C11.2138 15.9276 11.2654 15.803 11.2654 15.6731V13.7139H13.2246V15.6731C13.2246 15.803 13.2762 15.9276 13.3681 16.0194C13.4599 16.1113 13.5845 16.1629 13.7144 16.1629H15.1838C15.3137 16.1629 15.4383 16.1113 15.5301 16.0194C15.622 15.9276 15.6736 15.803 15.6736 15.6731V12.2445H16.6532C16.7831 12.2445 16.9077 12.1929 16.9995 12.101C17.0914 12.0092 17.143 11.8846 17.143 11.7547C17.1423 11.6819 17.125 11.6102 17.0925 11.545C17.06 11.4798 17.0131 11.4229 16.9554 11.3785C15.2739 9.96205 12.245 7.34654 12.245 7.34654Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M12.245 7.34654C12.245 7.34654 9.21513 9.96205 7.5219 11.3785C7.468 11.4255 7.42457 11.4832 7.39445 11.548C7.36432 11.6128 7.34817 11.6832 7.34705 11.7547C7.34705 11.8846 7.39865 12.0092 7.4905 12.101C7.58236 12.1929 7.70694 12.2445 7.83684 12.2445H8.81643V15.6731C8.81643 15.803 8.86804 15.9276 8.95989 16.0194C9.05175 16.1113 9.17633 16.1629 9.30623 16.1629H10.7756C10.9055 16.1629 11.0301 16.1113 11.122 16.0194C11.2138 15.9276 11.2654 15.803 11.2654 15.6731V13.7139H13.2246V15.6731C13.2246 15.803 13.2762 15.9276 13.3681 16.0194C13.4599 16.1113 13.5845 16.1629 13.7144 16.1629H15.1838C15.3137 16.1629 15.4383 16.1113 15.5301 16.0194C15.622 15.9276 15.6736 15.803 15.6736 15.6731V12.2445H16.6532C16.7831 12.2445 16.9077 12.1929 16.9995 12.101C17.0914 12.0092 17.143 11.8846 17.143 11.7547C17.1423 11.6819 17.125 11.6102 17.0925 11.545C17.06 11.4798 17.0131 11.4229 16.9554 11.3785C15.2739 9.96205 12.245 7.34654 12.245 7.34654Z"
      fill="black"
      fill-opacity="0.2"
    />
  </svg>
</template>

<script setup lang="ts"></script>
